<template>
  <div class="add-new-coupon">
    <title-bar
      submitBtn
      :submitBtnTitle="$t('messages.couponCreate')"
      :title="$t('messages.couponCreate')"
      @submitPressed="submitPressed"
    />
    <coupons-form
      v-if="coupon"
      :couponObject="coupon"
      :action="action"
      @clearAction="action = null"
    />
  </div>
</template>

<script>
import TitleBar from "@/components/TitleBar.vue";
import CouponsForm from "./components/CouponsForm.vue";

export default {
  components: {
    TitleBar,
    CouponsForm,
  },
  data() {
    return {
      coupon: {
          typeSelect: {},
          resortSelect: null
      },
      action: null,
    };
  },
  methods: {
    submitPressed() {
      this.action = "add";
    },
  },
};
</script>
